import React from 'react';

const ServiceCard01 = ({ data }) => {
    const { img, img02, title, number, bg, id, Icon, imageClassNames = '', href } = data;

    return (
        <div className="col-lg-4 col-md-4">
            <div className={`service_inner service_inner2 ${bg} relative h-[370px]`}>
                <div className="service_content d-flex align-self-center relative z-10">
                    <div className="icon_img">
                        {/* <img src={img} alt="" /> */}
                        {Icon} 
                    </div>
                    <div className="services_content_flex_cenrer">
                        <h6 className='text-orange-500'>{number}</h6>
                        <h4><a href={href}>{title}</a></h4>
                        <a href={href}>Daha Fazla <i className="fa fa-long-arrow-right" aria-hidden="true"></i></a>
                    </div>
                </div>
                <div className="main_img absolute bottom-0 right-0 w-full h-full flex justify-end items-end">
                    <img src={img02} alt="" className={imageClassNames} />
                </div>
            </div>
        </div>
    );
};

export default ServiceCard01;