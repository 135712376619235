import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer_above">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="footer_widget footer_widget_padding">
                <h4 className="widget_title">Donda Üretim</h4>
                <p>
                  Düşük maliyet'li yüksek kaliteli beton harcın innovasyon
                  lideri
                </p>
                <p>Kampanyalardan haberdar olmak için bize katıl</p>
                <div className="subscribe">
                  <form className="form-inline">
                    <div className="input-group mb-2 mr-sm-2">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email Adresi"
                      />
                    </div>
                    <button type="submit" className="btn">
                      Katıl
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="footer_widget footer_links">
                <h4 className="widget_title">Our Services</h4>
                <div className="footer_nav">
                  <ul className="footer_menu">
                    <li className="menu-item">
                      <a href="/products">Ürünler</a>
                    </li>
                    <li className="menu-item">
                      <a href="/project">Projeler</a>
                    </li>
                    <li className="menu-item">
                      <a href="/about">Hakkımızda</a>
                    </li>
                    <li className="menu-item">
                      <a href="/contact">İletişim</a>
                    </li>
                    {/* <li className="menu-item">
                      <a href="/project">Digital Marketing</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12">
              <div className="footer_widget footer_links">
                <h4 className="widget_title">Yaratıcı Linkler</h4>
                <div className="footer_nav">
                  <ul className="footer_menu">
                    <li className="menu-item">
                      <a href="/about">KVKK</a>
                    </li>
                    <li className="menu-item">
                      <a href="/blog-details">İade ve Değişim koşulları</a>
                    </li>
                    <li className="menu-item">
                      <a href="/blog-details">Kullanım koşulları</a>
                    </li>
                    <li className="menu-item">
                      <a href="https://api.whatsapp.com/send/?phone=+989112460024&text=Merhabalar! Ben Donda Buton Harcı hakkında bilgi almak istiyorum">Danışmanla konuş</a>
                    </li>
                    {/* <li className="menu-item">
                      <a href="/service-details">Be an Instructor</a>
                    </li>
                    <li className="menu-item">
                      <a href="/contact">Contact Us</a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="footer_widget">
                <h4 className="widget_title">Adres</h4>
                <ul>
                  <li>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <span>13 Madison Street NewYork, USA</span>
                  </li>
                  <li>
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                    <a href="mailto:dondafsk@gmail.com" target="_blank"><span>dondafsk@gmail.com</span></a>
                  </li>
                  <li>
                    <i className="fa fa-clock-o" aria-hidden="true"></i>
                    <span>Monday - Friday (9.00am - 9.00pm)</span>
                  </li>
                </ul>
                <div className="side_footer_social">
                  <ul className="bottom_social">
                    <li className="facebook">
                      <a href="#">
                        <i className="ion-social-facebook"></i>
                      </a>
                    </li>
                    <li className="twitter">
                      <a href="#">
                        <i className="ion-social-twitter"></i>
                      </a>
                    </li>
                    <li className="dribbble">
                      <a href="#">
                        <i className="ion-social-dribbble-outline"></i>
                      </a>
                    </li>
                    <li className="instagram">
                      <a href="#">
                        <i className="ion-social-instagram-outline"></i>
                      </a>
                    </li>
                    <li className="linkedin">
                      <a href="#">
                        <i className="ion-social-linkedin-outline"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="container">
          <div className="footer_bottom_inner">
            <div className="logo">
              <a href="index">
                <img src="images/logo_foot.svg" alt="Logo" />
              </a>
            </div>
            <div className="copyright">
              <p>
                &copy;2024{" "}
                <a href="https://themeforest.net/user/wpthemebooster">
                  Donda Üretim.
                </a>{" "}
                Tüm hakları saklıdır
              </p>
            </div>
            <div className="footer_nav_bottom">
              <ul>
                <li>
                  <a href="#">Kullanım koşulları</a>
                </li>
                <li>
                  <a href="#">KVKK</a>
                </li>
              </ul>
            </div>
            <div className="totop">
              <a href="#">
                <i className="ion-ios-arrow-up"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
