import React from "react";
import ServiceCard01 from "../../Components/ServiceCards/ServiceCard01";
import { GiEmptyMetalBucketHandle, GiFoundryBucket } from "react-icons/gi";
import { FaRegHandshake } from "react-icons/fa";
const ServiceHome01 = () => {
  const ServiceData = [
    {
      id: "1",
      img: "images/services/s1.png",
      img02: "images/services/ss1.png",
      Icon: <GiFoundryBucket className="w-10 h-10" />,
      number: "Ürünler",
      bg: "bg_1",
      title: "Tüm ürünleri gör",
      href: "/products",
    },
    {
      id: "2",
      img: "https://firebasestorage.googleapis.com/v0/b/donda-3bd58.appspot.com/o/IMG_20231210_060353_196-removebg-preview.png?alt=media&token=f098a749-ee2e-468e-aba3-964b3cf4c7fd",
      img02:
        "https://firebasestorage.googleapis.com/v0/b/donda-3bd58.appspot.com/o/IMG_20231210_060353_196-removebg-preview.png?alt=media&token=f098a749-ee2e-468e-aba3-964b3cf4c7fd",
      imageClassNames: "w-[62%]",
      Icon: <GiEmptyMetalBucketHandle className="w-10 h-10" />,
      number: "Ürünler",
      bg: "bg_2",
      title: "Donda Beton Harcı",
      href: "service-details/2",
    },
    {
      id: "3",
      img: "images/services/s3.png",
      img02:
        "https://firebasestorage.googleapis.com/v0/b/donda-3bd58.appspot.com/o/Shutterstock_205609195%20(1).png?alt=media&token=696bcdbe-463f-4803-aade-7deb2e26d05d",
      Icon: <FaRegHandshake className="w-10 h-10" />,
      number: "Servisler",
      bg: "bg_3",
      title: "Bayimiz olun!",
      href: "https://api.whatsapp.com/send/?phone=+989112460024&text=Merhabalar! Ben Bayi olamak hakkında bilgi almak istiyorum",
    },
  ];

  return (
    <div className="service">
      <div className="container-fluid g-0">
        <div className="row g-0">
          {ServiceData.map((data) => (
            <ServiceCard01 key={data.id} data={data} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceHome01;
