import React from 'react';
import AboutBelowContent02 from '../../Components/ExperienceBlocks/AboutBelowContent02';

const ExperienceAbout = () => {
    const headerData = {
        heading: 'Hayalı Gerçeğe Dönüştürmek',
        subheading: 'Donda Üretim Hakkında',
    };

    const ExpericenData = [
        {
            id: '1',
            title: 'Msiyonumuz',
            description: 'Düşük maliyetl ile sağlam ve güvenli malzeme sağlamak',
            img: 'images/about/t1.png'

        },
        {
            id: '2',
            title: 'Vizyon',
            description: "Dünya'da güven ve hızla hatırlanmak",
            img: 'images/about/t2.png'
        },
    ];
    return (
        <div className="experience">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="group_image_holder type_1">
                            <div className="expe_box">
                                <div className="expe_box_inner">
                                    <h1>10</h1>
                                    Yıl Tecrübe İle
                                </div>
                            </div>
                            <img src="images/about/1.png" alt="" />
                            <div className="object">
                                <img src="images/about/3.png" alt="About" />
                                <img src="images/about/3.png" alt="About" />
                                <img src="images/about/3.png" alt="About" />
                                <img src="images/about/s1.png" alt="About" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="experience_content about">
                            <div className="section_header">
                                <h6 className="section_sub_title">{headerData.subheading}</h6>
                                <h1 className="section_title">{headerData.heading}</h1>
                                <p className="section_desc">Maliyetlerin düşürülmesi amacıyla inşaat malzemelerinin üretimi ve sanayileşme yaklaşımıyla uygulanması alanında inşaat deseninin yeniden düzenlenmesi ve standart bir ortam yaratılması amacıyla ülke inşaat sektöründe köklü değişiklikler yapılması gerekmektedir.</p> <br />
                                <p className="section_desc">Aynı bakış açısıyla DONDA şirketi, modern Avrupa teknolojisinin de yardımıyla, 2013 yılından itibaren İzmir Türkiye'de "ön karışımlı harç" fabrikası kurarak DONDA markası altında ürününü piyasaya sürmüştür.</p> <br />
                                <p className="section_desc">Kaliteli hammadde kullanımı, donanımlı bir laboratuvar kurulması, ve üretim süreci boyunca teknik uzmanlar tarafından süreç takibi ve kontrolü uluslararası standartlarda ürettiği ürünü tüketicinin kullanımına sunmuştur. On yıllık bu faaliyetin sonucunda tamamlanan projelerde onbinlerce memnun müşterinin varlığı ve dünyanın farklı yerlerinde 30 satış şubesinin kurulması sağlanmaktadır.</p>
                                <div className="about_below">
                                    {
                                        ExpericenData.map(data =>
                                            <AboutBelowContent02
                                                key={data.id}
                                                data={data}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                            <a className="button" href="/about">Daha Fazla</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExperienceAbout;