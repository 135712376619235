import React from 'react';

const Slider = () => {
    return (
        <div className="theme_slider_1">
            <div className="slider">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="slide_content pt-[100px] md:pt-[250px]">
                            <a href="/" className="light_mode_logo inline-flex md:hidden w-[62%] justify-center"><img src="/images/logo.svg" alt="logo" /></a>
                                <h5 className="sub_heading">Hemen Ara!<span> (+98) 134 265 20 05 </span></h5>
                                <h2 className="heading">DONDA Üretim Firması</h2>
                                <p className="desc">Modern İnşaat Ürünleri Üreticisi</p>
                                <div className="slider_button">
                                    <a href="/about" className="button">Daha Fazla</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 d-flex align-items-end">
                            <div className="layer_object">
                                <img src="images/slider/layer_2.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slider;