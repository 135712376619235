import React from 'react';
import { Controller, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import TestimonialCard01 from '../../Components/TestimonialCards/TestimonialCard01';
// import './Testimonial.css';

const Testimonial01 = (props) => {
    const TestimonialData01 = [
        {
            id: '1',
            img: 'images/reviewer1.png',
            country: 'Istanbul',
            name: 'Mehmet K.:',
            text: "İşlerini titizlikle ve özveriyle yürüten bir firma. Yaptıkları işte kaliteye verdikleri önem beni etkiledi. Evinin yeniden yapılanması sürecinde sürekli iletişimde kaldık ve her aşamada bilgilendirildik. Sonuçtan çok memnun kaldık ve Donda'nın profesyonel ekibine teşekkür ederim."
        },
        {
            id: '2',
            img: 'images/reviewer4.png',
            country: 'Ankara',
            name: 'Ayşe Y.:',
            text: "Projemizi hayata geçirirken sadece bir inşaat firması değil, aynı zamanda bir iş ortağı gibi davrandı. Başlangıçtan bitişe kadar olan süreçte, ekip her zaman samimi ve yardımseverdi. İşlerini zamanında ve eksiksiz bir şekilde tamamladıkları için Donda'ya minnettarız. Kesinlikle tavsiye ederim."
        },
        {
            id: '3',
            img: 'images/reviewer3.png',
            country: 'İzmir',
            name: 'Serkan G.',
            text: "Donda, kaliteden ödün vermeden işlerini tamamlama konusundaki kararlılığıyla öne çıkıyor. Evinin yeniden tasarımı için Donda'yı seçmek, doğru bir karar olduğunu kanıtladı. Süreç boyunca gösterdikleri şeffaflık ve profesyonellik, firma ile çalışmaktan keyif almayı sağladı. Herkese gönül rahatlığıyla tavsiye ederim.",
            },
        {
            id: '4',
            img: 'images/reviewer1.png',
            country: 'Antalya',
            name: 'Ahmet T.',
            text: "Müşteri memnuniyetini ön planda tutan bir anlayışa sahip. İşlerini büyük bir özveri ve titizlikle yürüten bu ekip, projemizin her aşamasında bizi bilgilendirdi ve isteklerimize özen gösterdi. Sonuç olarak, Donda İnşaat'ın profesyonelliği ve kalitesi bizi etkiledi. Kendilerine teşekkür ederim.  "
        },
        {
            id: '5',
            img: 'images/reviewer1.png',
            country: 'New York',
            name: 'Ömer S.',
            text: "Sadece yapısal açıdan değil, aynı zamanda müşteri memnuniyeti konusunda da üst düzey bir performans sergiliyor. Projemizdeki detaylara verdikleri önem ve esneklikleri sayesinde, isteklerimizi tam anlamıyla karşılayabildik. Donda'ya teşekkür ederim, gelecekteki projelerimizde de tercihimiz olacaklar."
        },

    ]
    return (
        <div className={`testimonial ${props.pb} `}>
            <div className="container">
                <div className="section_header text-center">
                    <div className="shadow_icon"><img src="images/shadow_icon3.png" alt="" /></div>
                    <h6 className="section_sub_title">Müşterilerimizin Düşünceleri</h6>
                    <h1 className="section_title">Müşterilerimiz Hakkımızda Ne Düşünüyorlar</h1>
                    <p className="section_desc">Donda Üretim'in memnuniyet ankentine katılan müşterilerimizin düşünceleri </p>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Swiper
                            className="owl_testimonial1 owl-carousel owl-theme"
                            modules={[Pagination, Controller]}
                            effect={"slide"}
                            breakpoints={{
                                640: {
                                    width: 640,
                                    slidesPerView: 1,
                                },
                                768: {
                                    width: 768,
                                    slidesPerView: 2,
                                },
                            }}
                            loop={true}
                            controller={{ inverse: true }}
                            spaceBetween={50}
                            // centeredSlides={true}
                            pagination={{
                                el: '.testimonial-pagination',
                                clickable: true,
                                // dynamicBullets: true,
                            }}
                        >

                            {
                                TestimonialData01.map(data =>
                                    <SwiperSlide>
                                        <TestimonialCard01
                                            key={data.id}
                                            data={data}
                                        /></SwiperSlide>)
                            }


                        </Swiper>

                        {/* <!-- Add Pagination --> */}
                        <div className="testimonial-pagination"></div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonial01;