import React from 'react';
import AboutBelowContent from '../../Components/ExperienceBlocks/AboutBelowContent';

const Experience = () => {
    const headerData = {
        img: 'images/about/shadow_icon1.png',
        heading: 'Modern İnşaat Ürünleri',
        subheading: 'Donda Üretim Hakkında',
    };

    const ExpericenData = [
        {
            id: '1',
            title: 'Avrupa Teknolojisi',
            description: "Aynı bakış açısıyla DONDA şirketi, modern Avrupa teknolojisinin de yardımıyla, 2013 yılından itibaren İzmir Türkiye'de 'ön karışımlı harç' fabrikası kurarak DONDA markası altında ürününü piyasaya sürmüştür.r"

        },
        {
            id: '2',
            title: "En Kaliteli",
            description: 'Kaliteli hammadde kullanımı, donanımlı bir laboratuvar kurulması, ve üretim süreci boyunca teknik uzmanlar tarafından süreç takibi ve kontrolü uluslararası standartlarda ürettiği ürünü tüketicinin kullanımına sunmuştur.'
        },
    ];

    return (
        <div className="experience section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="group_image_holder type_1">
                            <div className="expe_box">
                                <div className="expe_box_inner">
                                    <h1>10</h1>
                                    Yıl Tecrübe İle Yanınızda
                                </div>
                            </div>
                            <div className="image_object">
                                <img src="images/about/1.png" alt="" />
                                <div className="object">
                                    <img src="images/about/3.png" alt="About" />
                                    <img src="images/about/3.png" alt="About" />
                                    <img src="images/about/3.png" alt="About" />
                                    <img src="images/about/s1.png" alt="About" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="experience_content">
                            <div className="section_header">
                                <div className="shadow_icon"><img src={headerData.img} alt="" /></div>
                                <h6 className="section_sub_title">{headerData.subheading}</h6>
                                <h1 className="section_title">{headerData.heading}</h1>
                                <p className="section_desc">Maliyetlerin düşürülmesi amacıyla inşaat malzemelerinin üretimi ve sanayileşme yaklaşımıyla uygulanması alanında inşaat deseninin yeniden düzenlenmesi ve standart bir ortam yaratılması amacıyla ülke inşaat sektöründe köklü değişiklikler yapılması gerekmektedir.</p>
                                <div className="about_below">
                                    {
                                        ExpericenData.map(data =>
                                            <AboutBelowContent
                                                key={data.id}
                                                data={data}
                                            />
                                        )
                                    }

                                </div>
                            </div>
                            <a className="button" href="/about">Daha Fazla</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Experience;