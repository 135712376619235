const ServiceData = [
    {
        id: '1',
        img: 'images/services/icon6.png',
        bg: 'https://firebasestorage.googleapis.com/v0/b/donda-3bd58.appspot.com/o/IMG_20231210_060353_196-removebg-preview.png?alt=media&token=f098a749-ee2e-468e-aba3-964b3cf4c7fd',
        title: 'Donda Beton',
        description: 'Basitçe söylemek gerekirse DONDA beton harcı, duvar yapımında eski ve geleneksel harç yerine kullanılan ve birçok avantajı olan özel bir yapıştırıcı türüdür.',
    },
    // {
    //     id: '2',
    //     img: 'images/services/icon2.png',
    //     bg: 'images/services/bg-1.png',
    //     title: "üç amaçlı, 15 KG'lık Kova",
    //     description: 'Kova hakkında bilgileri',
    // },  
];

export default ServiceData;
