import React from 'react';
import PageHeader from '../../Components/common/PageHeader';
import ServiceContainer03 from '../../Containers/ServiceContainers/ServiceContainer03';
import { useParams } from 'react-router-dom';
import products from '../../data/products';


const ServiceDetails = () => {
    const params = useParams()
    console.log('these are the params', params)
    const product = products.find(product => product.id == params.pid)
    console.log('this is the product', product)
    return (
        <>
            <PageHeader activeClass02='active' routeName01='Services' routeName02='Comertial Construction' heading='Comertial Construction' />
            <ServiceContainer03 />
        </>
    );
};

export default ServiceDetails;