import React from "react";
import Video from "../../Components/BannerElements/Video";
import Testimonial01 from "../Testimonials/Testimonial01";
import { IoLogoWhatsapp } from "react-icons/io5";

const ServiceContainer03 = () => {
  return (
    <div className="main_wrapper">
      <div className="container">
        <div className="project_details section">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="project_details_inner">
                <div className="post_img flex justify-center">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/donda-3bd58.appspot.com/o/IMG_20231210_060353_196-removebg-preview.png?alt=media&token=f098a749-ee2e-468e-aba3-964b3cf4c7fd"
                    className="w-[60%]"
                    alt="blog"
                  />
                </div>
                <div className="post_content">
                  <div className="post_header">
                    <h3 className="post_title">Donda Beton Harcı</h3>
                  </div>
                  <div className="fulltext">
                    <p>
                      Maliyetlerin düşürülmesi amacıyla inşaat malzemelerinin
                      üretimi ve sanayileşme yaklaşımıyla uygulanması alanında
                      inşaat deseninin yeniden düzenlenmesi ve standart bir
                      ortam yaratılması amacıyla ülke inşaat sektöründe köklü
                      değişiklikler yapılması gerekmektedir.
                    </p>

                    <p>
                      Aynı bakış açısıyla DONDA şirketi, modern Avrupa
                      teknolojisinin de yardımıyla, 2013 yılından itibaren İzmir
                      Türkiye'de "ön karışımlı harç" fabrikası kurarak DONDA
                      markası altında ürününü piyasaya sürmüştür.
                    </p>
                    <p>
                      Kaliteli hammadde kullanımı, donanımlı bir laboratuvar
                      kurulması, ve üretim süreci boyunca teknik uzmanlar
                      tarafından süreç takibi ve kontrolü uluslararası
                      standartlarda ürettiği ürünü tüketicinin kullanımına
                      sunmuştur. On yıllık bu faaliyetin sonucunda tamamlanan
                      projelerde onbinlerce memnun müşterinin varlığı ve
                      dünyanın farklı yerlerinde 30 satış şubesinin kurulması
                      sağlanmaktadır.
                    </p>
                    <h4 className="widget_title">DONDA beton harcı nedir?</h4>
                    <p>
                      İnşaat sektöründe her zaman üzerinde durulan en önemli
                      noktalardan biri binanın stilizasyonu ve standartlarına
                      uygunluğunun gerekliliğidir. Bu konu o kadar önemlidir ki,
                      binanın ağırlığının düşük olması en önemli avantaj ve
                      özelliklerinden biri olarak kabul edilmektedir. DONDA
                      beton harcının inşaat sektöründe üretilmesi ve
                      kullanılmasından önce, eski bloklar yerine hafif bloklar
                      kullanılarak yapının hafifletilmesinin inşaat sektörü
                      mühendisleri tarafından çok fazla düşünüldüğünü bilmek
                      ilginçtir. DONDA beton harcının avantajları nedeniyle
                      birçok kişi hala inşaatlarda duvar yapımında eski harçları
                      kullanıyor. Bu yöntem ekonomik olmasının yanı sıra mali
                      maliyetler açısından da binanın ölü yükünü arttırmaktadır.
                    </p>
                    <p>
                      Basitçe söylemek gerekirse DONDA beton harcı, duvar
                      yapımında eski ve geleneksel harç yerine kullanılan ve
                      birçok avantajı olan özel bir yapıştırıcı türüdür.
                    </p>

                    <h4 className="widget_title">
                      Donda Buton Harcının avantajları
                      <span className="title_line"></span>
                    </h4>
                    {/* <p className="margin_o_para">The following problems may arise withe house key duplication -</p> */}
                    <ul className="point_order">
                      <li>
                        Sadece (Hebelx ve çimento) çesitli tipteki bloklarin,
                        duvar Insası sırasında dizilerek uygulanmasını sağar
                      </li>
                      <li>
                        Dikey ve yatay yüzeylerde kolay bir şekilde çalişabilme
                        Özelliği sunar
                      </li>
                      <li>
                        Duvarların iç ve dış yüzeylerini düzlestirerek bina
                        cepheleri icin de pürüzsüz bir alt yüzey olusturur
                      </li>
                      <li>
                        Harç yapma işlemine gerek kalmaksızın, kullanıma hazır
                        ve pratiktir
                      </li>
                      <li>
                        Duvar kaplama işlemlerinde kum ve çimento harcına göre
                        oldukça düşük maliyetlidir
                      </li>
                      <li>
                        Harç karma işlemi, Işçilik ve zaman tüketimi açısından
                        maksimum tasarruf sağlar
                      </li>
                      <li>
                        Kum ve çimento harcına oranla daha az malzemeyle daha
                        çok iş ortaya koyar
                      </li>
                      <li>
                        Her türlü ısı değişimlerine, iklim koşullarına ve neme
                        karşı dayanıklıdır
                      </li>
                      <li>Duvar üzerine bindirilen gereksiz yükü azaltır</li>
                      <li>
                        Kullanımı kolay olduğu için ekstra bir katkı maddesine
                        gereksinim duymaz
                      </li>
                      <li>
                        Blok parçalariını bir brine hızlı bir şekilde
                        yapıştırarak zaman kazandırır
                      </li>
                      <li>Düşük maliyet ve sıfır atık sunar</li>
                      <li>
                        Uygulaması kolay ve hızlı olduğu için bir uzmana ihtiyaç
                        duymaz
                      </li>
                      <li>Her seyden de Oönemlisi çevre dostudur</li>
                    </ul>

                    <div className="post_gallery">
                      <div className="row">
                        <div className="col-lg-6 col-sm-6">
                          <div className="anim_box">
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/donda-3bd58.appspot.com/o/IMG_20231210_060353_196.jpg?alt=media&token=ffd40a28-a65c-4a69-b06d-afe96d794616"
                              alt="donda beton"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-6">
                          <div className="anim_box">
                            <img
                              className="sm-margin-bottom"
                              src="https://firebasestorage.googleapis.com/v0/b/donda-3bd58.appspot.com/o/IMG_20231210_060353_240.jpg?alt=media&token=c21f3180-5c9e-4866-a424-a2a807f99d1b"
                              alt="donda beton"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <p>
                      Burglars prefer to work in the cover of dark. By setting
                      up lighting around your garage can aid in keeping burglars
                      at bay. Install a sensor light to turn on as you enter the
                      driveway and approach the garage. Not only will it prove a
                      burglar deterrent it will also assist you with seeing
                      better to come home late.
                    </p>

                    {/* <Video bg='/images/services/video_bg.png' /> */}
                    <video width="320" height="240" controls>
                      <source
                        src="https://firebasestorage.googleapis.com/v0/b/donda-3bd58.appspot.com/o/VID-20231214-WA0026.mp4?alt=media&token=74dd9ff7-2089-4b92-affd-13e8b297ba7d"
                        type="video/mp4"
                      />
                      {/* <source src="movie.ogg" type="video/ogg"/> */}
                      Your browser does not support the video tag.
                    </video>
                    <p className="mt-4">
                      <span className="font-bold">İçindekiler: </span> çimento,
                      tip 2 portland çimentosu, beyaz çimento, maksimum 1 mm
                      boyutunda granüle silika agregası
                    </p>
                    <p className="mt-4">
                      <span className="font-bold">Renk: </span>Gri, Beyaz
                    </p>
                    <p className="mt-4">
                      <span className="font-bold">Basınç dayanımı: </span> 15
                      MPa'dan fazla
                    </p>
                    <p className="mt-4">
                      <span className="font-bold">
                        Suya ve çevre koşullarına dayanıklılık:{" "}
                      </span>{" "}
                      ASTM 10-1660 standardına göre
                    </p>
                    <p className="mt-4">
                      <span className="font-bold">
                        Uygulanabilir kalınlık:{" "}
                      </span>{" "}
                      2 - 5 mm
                    </p>
                    <p className="mt-4">
                      <span className="font-bold">Raf ömrü: </span> 2 saat
                    </p>
                    <p className="mt-4">
                      <span className="font-bold">Diğer katkı maddeleri: </span>{" "}
                      yapışmayı ve direnci arttırmak için su tutucu polimer
                      katkı maddeleri ve katkı maddeleri
                    </p>
                    <p className="mt-4">
                      <span className="font-bold">Paket ağırlığı: </span> 20 kg
                    </p>

                    <a href="https://api.whatsapp.com/send/?phone=+989112460024&text=Merhabalar! Ben Donda Buton Harcı hakkında bilgi almak istiyorum" target="_blank" className="w-fit py-[10px] px-8 border-2 border-[--primary-color] flex items-center space-x-5 cursor-pointer rounded-sm">
                      <IoLogoWhatsapp className="w-10 h-10"/>
                      <span>Hemen sipariş ver</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <div className="sidebar">
                <div id="widgetnav" className="widget widget_menu">
                  <div className="sidenav">
                    <ul className="side_menu">
                      <li className="menu-item active">
                        <a href="#">
                          <img src="/images/services/cone_1.png" alt="" />
                          <img src="/images/services/2-white.png" alt="" />
                          Avrupa Teknolojisi 
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="#">
                          <img src="/images/services/9-blue.png" alt="" />
                          <img src="/images/services/cone_2.png" alt="" />
                          Düşük Maliyet
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="#">
                          <img src="/images/services/cone_3.png" alt="" />
                          <img src="/images/services/5-blue.png" alt="" />
                          Kaliteli Hammadde
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="#">
                          <img src="/images/services/cone_4.png" alt="" />
                          <img src="/images/services/1-white.png" alt="" />
                          Hafif Yapı
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="#">
                          <img src="/images/services/cone_5.png" alt="" />
                          <img src="/images/services/11-white.png" alt="" />
                          Çevresel Uyum
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="#">
                          <img src="/images/services/cone_6.png" alt="" />
                          <img src="/images/services/10-white.png" alt="" />
                          Yüksek Yapışma Gücü
                        </a>
                      </li>
                      <li className="menu-item">
                        <a href="#">
                          <img src="/images/services/cone_7.png" alt="" />
                          <img src="/images/services/7-white.png" alt="" />
                          Çok Yönlü Uygulama
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div id="custom_2" className="widget widget_side_contact">
                  <div className="side_contact">
                    <div className="side_contact_top">
                      <div className="side_contact_inner">
                        <p>Daha Fazla Bilgiye mi ihtiacınız var ?</p>
                        <h4>Hemen Bizimle İletişime geçin</h4>
                      </div>
                    </div>
                    <a href="https://api.whatsapp.com/send/?phone=+989112460024&text=Merhabalar! Ben Donda Buton Harcı hakkında bilgi almak istiyorum" target="_blank" className="side_phone_inner hover:text-[#dfc420] hover:bg-white border border-[#dfc420] transition-all duration-500">
                      {/* <a href="https://api.whatsapp.com/send/?phone=+989112460024&text=Merhabalar! Ben Donda Buton Harcı hakkında bilgi almak istiyorum" target="_blank"> */}
                      (+98) 911 246 00 24
                      {/* </a> */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Testimonial01 pb="pd_btom_80 pd_top_80" />
    </div>
  );
};

export default ServiceContainer03;
